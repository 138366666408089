var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "van-common-detail" },
      [
        _c("van-cell", [
          _c("div", { staticClass: "goods-table" }, [
            _c(
              "table",
              {
                staticClass:
                  "inner-goods-table payment-table payment-table-width",
                attrs: { border: "1" }
              },
              [
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.statusFormat(
                            String(_vm.settlementForm.settlementType),
                            "StatementType"
                          )
                        ) +
                        " "
                    )
                  ])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("订单名称")]),
                  _c("td", [_vm._v(_vm._s(_vm.settlementForm.orderName))])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("订单编号")]),
                  _c("td", [_vm._v(_vm._s(_vm.settlementForm.orderNo))])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("关联合同名称")]),
                  _c("td", [_vm._v(_vm._s(_vm.settlementForm.contractName))])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("合同编号")]),
                  _c("td", [_vm._v(_vm._s(_vm.settlementForm.contractNumber))])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("付款方类型")]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.utils.statusFormat(
                          String(_vm.settlementForm.payerType),
                          "EnterpriseOrgType"
                        )
                      )
                    )
                  ])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("付款方")]),
                  _c("td", [_vm._v(_vm._s(_vm.settlementForm.payerOrgName))])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("付款方开户行")]),
                  _c("td", [
                    _vm._v(_vm._s(_vm.settlementForm.payerAccountBank))
                  ])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("付款方账号")]),
                  _c("td", [_vm._v(_vm._s(_vm.settlementForm.paymentAccount))])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("收款方类型")]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.utils.statusFormat(
                          String(_vm.settlementForm.collectionMethodType),
                          "EnterpriseOrgType"
                        )
                      )
                    )
                  ])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("收款方")]),
                  _c("td", [
                    _vm._v(_vm._s(_vm.settlementForm.beneficiaryOrgName))
                  ])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("收款方开户行")]),
                  _c("td", [
                    _vm._v(_vm._s(_vm.settlementForm.beneficiaryAccountBank))
                  ])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("收款方账号")]),
                  _c("td", [
                    _vm._v(_vm._s(_vm.settlementForm.accountsReceivable))
                  ])
                ])
              ]
            )
          ])
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "van-common-detail" },
      [
        _c(
          "van-cell",
          [
            _c("h3", [_vm._v("费用信息")]),
            _vm._l(_vm.settlementForm.statementCalculationInfoVoList, function(
              feeTypeItem
            ) {
              return _c(
                "table",
                {
                  key: feeTypeItem.id,
                  staticClass:
                    "inner-goods-table payment-table payment-table-width",
                  attrs: { border: "1" }
                },
                [
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "header-th", attrs: { colspan: "2" } },
                      [
                        _c("span", [_vm._v("费用类型：")]),
                        _c("span", [_vm._v(_vm._s(feeTypeItem.feeProject))])
                      ]
                    )
                  ]),
                  [0, 12].includes(Number(feeTypeItem.feeType))
                    ? _c("tr", [
                        _c("td", { staticClass: "header-th" }, [
                          _c("span", [_vm._v("预付比例（%）：")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.moneyFormat(
                                  feeTypeItem.advanceChargeProportion
                                )
                              )
                            )
                          ])
                        ]),
                        _c("td", { staticClass: "header-th" }, [
                          _c("span", [_vm._v("预估汇率：")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.moneyFormat(
                                  feeTypeItem.agreedExchangeRate
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._l(
                    _vm._.filter(_vm.settlementForm.statementFeeInfoVoList, {
                      feeType: feeTypeItem.feeType
                    }),
                    function(item, index) {
                      return _c(
                        "tbody",
                        { key: index },
                        [
                          [0, 12].includes(Number(feeTypeItem.feeType))
                            ? [
                                _c("tr", [
                                  _c("td", [_vm._v(" PI ")]),
                                  _c("td", [_vm._v(_vm._s(item.pi))])
                                ]),
                                _c("tr", [
                                  _c("td", [_vm._v("货物名称")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(item.productName || item.cargoName)
                                    )
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", [_vm._v("提单号")]),
                                  item.pickUpNo
                                    ? _c("td", [
                                        _vm._v(
                                          " " + _vm._s(item.pickUpNo) + " "
                                        )
                                      ])
                                    : _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.cabinetNoFormat(
                                                item.logisticsBscInfoVoList,
                                                "pickUpNo"
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                ]),
                                _c("tr", [
                                  _c("td", [_vm._v("柜号")]),
                                  item.cabinetNo
                                    ? _c("td", [
                                        _vm._v(
                                          " " + _vm._s(item.cabinetNo) + " "
                                        )
                                      ])
                                    : _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.cabinetNoFormat(
                                                item.logisticsPickUpInfoVoList,
                                                "cabinetNo"
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                ])
                              ]
                            : [
                                _c("tr", [
                                  _c("td", [_vm._v(" 柜号 ")]),
                                  _c("td", [_vm._v(_vm._s(item.cabinetNo))])
                                ]),
                                _c("tr", [
                                  _c("td", [_vm._v("提单号")]),
                                  _c("td", [
                                    _vm._v(" " + _vm._s(item.pickUpNo) + " ")
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", [_vm._v("PI")]),
                                  _c("td", [
                                    _vm._v(" " + _vm._s(item.pi) + " ")
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", [_vm._v("货物名称")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(item.productName || item.cargoName)
                                    )
                                  ])
                                ])
                              ],
                          _c("tr", [
                            _c("td", [_vm._v("国家")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.statusFormat(
                                    item.country,
                                    "Country"
                                  )
                                )
                              )
                            ])
                          ]),
                          [0, 12].includes(Number(feeTypeItem.feeType))
                            ? _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      !_vm._.isEmpty(
                                        _vm._.filter(
                                          _vm.settlementForm
                                            .statementFeeInfoVoList,
                                          { feeType: feeTypeItem.feeType }
                                        )
                                      )
                                        ? "原币单价 " +
                                            _vm.utils.statusFormat(
                                              _vm._.filter(
                                                _vm.settlementForm
                                                  .statementFeeInfoVoList,
                                                { feeType: feeTypeItem.feeType }
                                              )[0].currency,
                                              "OriginalCurrency"
                                            ) +
                                            "/KG"
                                        : "原币单价"
                                    )
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.utils.moneyFormat(item.unitPrice)
                                    )
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  "" +
                                    ([0, 12].includes(
                                      Number(feeTypeItem.feeType)
                                    )
                                      ? "合同重量（KG）"
                                      : "实发重量（KG）")
                                )
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.moneyFormat(item.weight) ||
                                    _vm.utils.moneyFormat(item.actualWeight)
                                )
                              )
                            ])
                          ]),
                          ![0, 12].includes(Number(feeTypeItem.feeType))
                            ? _c("tr", [
                                _c("td", [_vm._v("件数")]),
                                _c("td", [_vm._v(_vm._s(item.pieceNumber))])
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c("td", [_vm._v("原币总金额")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.moneyFormat(item.currencyMoney)
                                )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  "金额" +
                                    ([12, 13, 14].includes(
                                      Number(feeTypeItem.feeType)
                                    )
                                      ? ""
                                      : "（元）")
                                )
                              )
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.utils.moneyFormat(item.money)))
                            ])
                          ])
                        ],
                        2
                      )
                    }
                  ),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2", align: "left" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            "合计金额" +
                              ([12, 13, 14].includes(
                                Number(feeTypeItem.feeType)
                              )
                                ? ""
                                : "（元）") +
                              ":" +
                              _vm.utils.moneyFormat(
                                _vm.feeTypeTotal(feeTypeItem.feeType)
                              )
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2", align: "left" } }, [
                      _vm._v(" " + _vm._s("备注：" + feeTypeItem.remarks) + " ")
                    ])
                  ])
                ],
                2
              )
            })
          ],
          2
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "van-common-detail" },
      [
        _c("van-cell", [
          _c(
            "table",
            {
              staticClass: "inner-goods-table payment-table",
              attrs: { border: "1" }
            },
            [
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "header-th", attrs: { colspan: "3" } },
                  [_c("span", [_vm._v("货款结算明细")])]
                )
              ]),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("序号")]),
                  _c("td", [_vm._v("项目")]),
                  _c("td", [_vm._v("金额（元）")])
                ])
              ]),
              _vm._l(
                _vm.settlementForm.statementCalculationInfoVoList,
                function(it, idx) {
                  return _c("tr", { key: idx }, [
                    _c("td", [_vm._v(_vm._s(idx + 1))]),
                    _c("td", [_vm._v(_vm._s(it.feeProject))]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.utils.moneyFormat(it.feeMoney)))
                    ])
                  ])
                }
              )
            ],
            2
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "van-common-detail" },
      [
        _c("van-cell", { attrs: { title: "本次应收合计（元）" } }, [
          _vm._v(" " + _vm._s(_vm.utils.moneyFormat(_vm.allTotal())) + " ")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }