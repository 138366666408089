<template>
  <div>
    <div class="van-common-detail">
      <van-cell>
        <div class="goods-table">
          <table class="inner-goods-table payment-table payment-table-width" border="1">
            <tr>
              <!-- <th colspan="2" class="header-th">
                <span>{{ utils.statusFormat(String(settlementForm.settlementType), 'StatementType') }}</span>
              </th> -->
              <td colspan="2">
                {{ utils.statusFormat(String(settlementForm.settlementType), 'StatementType') }}
              </td>
            </tr>
            <tr>
              <td>订单名称</td><td>{{ settlementForm.orderName }}</td>
            </tr>
            <tr>
              <td>订单编号</td><td>{{ settlementForm.orderNo }}</td>
            </tr>
            <tr>
              <td>关联合同名称</td><td>{{ settlementForm.contractName }}</td>
            </tr>
            <tr>
              <td>合同编号</td><td>{{ settlementForm.contractNumber }}</td>
            </tr>
            <tr>
              <td>付款方类型</td><td>{{ utils.statusFormat(String(settlementForm.payerType), 'EnterpriseOrgType') }}</td>
            </tr>
            <tr>
              <td>付款方</td><td>{{ settlementForm.payerOrgName }}</td>
            </tr>
            <tr>
              <td>付款方开户行</td><td>{{ settlementForm.payerAccountBank }}</td>
            </tr>
            <tr>
              <td>付款方账号</td><td>{{ settlementForm.paymentAccount }}</td>
            </tr>
            <tr>
              <td>收款方类型</td><td>{{ utils.statusFormat(String(settlementForm.collectionMethodType), 'EnterpriseOrgType') }}</td>
            </tr>
            <tr>
              <td>收款方</td><td>{{ settlementForm.beneficiaryOrgName }}</td>
            </tr>
            <tr>
              <td>收款方开户行</td><td>{{ settlementForm.beneficiaryAccountBank }}</td>
            </tr>
            <tr>
              <td>收款方账号</td><td>{{ settlementForm.accountsReceivable }}</td>
            </tr>
          </table>
        </div>
      </van-cell>
    </div>
    <div class="van-common-detail">
      <van-cell>
        <h3>费用信息</h3>
        <table v-for="feeTypeItem in settlementForm.statementCalculationInfoVoList" :key="feeTypeItem.id" class="inner-goods-table payment-table payment-table-width" border="1">
          <tr>
            <td colspan="2" class="header-th">
              <span>费用类型：</span>
              <span>{{ feeTypeItem.feeProject }}</span>
            </td>
          </tr>
          <tr v-if="[0, 12].includes(Number(feeTypeItem.feeType))">
            <td class="header-th">
              <span>预付比例（%）：</span>
              <span>{{ utils.moneyFormat(feeTypeItem.advanceChargeProportion) }}</span>
            </td>
            <td class="header-th">
              <span>预估汇率：</span>
              <span>{{ utils.moneyFormat(feeTypeItem.agreedExchangeRate) }}</span>
            </td>
          </tr>
          <tbody v-for="(item, index) in _.filter(settlementForm.statementFeeInfoVoList, { feeType: feeTypeItem.feeType })" :key="index">
            <template v-if="[0, 12].includes(Number(feeTypeItem.feeType))">
              <tr>
                <td>
                  PI
                </td><td>{{ item.pi }}</td>
              </tr>
              <tr>
                <td>货物名称</td><td>{{ item.productName || item.cargoName }}</td>
              </tr>
              <tr>
                <td>提单号</td>
                <td v-if="item.pickUpNo">
                  {{ item.pickUpNo }}
                </td>
                <td v-else>
                  {{ cabinetNoFormat(item.logisticsBscInfoVoList,'pickUpNo') }}
                </td>
              </tr>
              <tr>
                <td>柜号</td>
                <td v-if="item.cabinetNo">
                  {{ item.cabinetNo }}
                </td>
                <td v-else>
                  {{ cabinetNoFormat(item.logisticsPickUpInfoVoList,'cabinetNo') }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td>
                  柜号
                </td><td>{{ item.cabinetNo }}</td>
              </tr>
              <tr>
                <td>提单号</td>
                <td>
                  {{ item.pickUpNo }}
                </td>
              </tr>
              <tr>
                <td>PI</td>
                <td>
                  {{ item.pi }}
                </td>
              </tr>
              <tr>
                <td>货物名称</td><td>{{ item.productName || item.cargoName }}</td>
              </tr>
            </template>
            <tr>
              <td>国家</td><td>{{ utils.statusFormat(item.country,'Country') }}</td>
            </tr>
            <tr v-if="[0, 12].includes(Number(feeTypeItem.feeType))">
              <td>{{ !_.isEmpty(_.filter(settlementForm.statementFeeInfoVoList, { feeType: feeTypeItem.feeType }))?`原币单价 ${utils.statusFormat(_.filter(settlementForm.statementFeeInfoVoList, { feeType: feeTypeItem.feeType })[0].currency,'OriginalCurrency')}/KG`:'原币单价' }}</td><td>{{ utils.moneyFormat(item.unitPrice) }}</td>
            </tr>
            <tr>
              <td>{{ `${[0, 12].includes(Number(feeTypeItem.feeType))?'合同重量（KG）':'实发重量（KG）'}` }}</td><td>{{ utils.moneyFormat(item.weight) || utils.moneyFormat(item.actualWeight) }}</td>
            </tr>
            <tr v-if="![0, 12].includes(Number(feeTypeItem.feeType))">
              <td>件数</td><td>{{ item.pieceNumber }}</td>
            </tr>
            <tr>
              <td>原币总金额</td><td>{{ utils.moneyFormat(item.currencyMoney) }}</td>
            </tr>
            <tr>
              <td>{{ `金额${[12,13,14].includes(Number(feeTypeItem.feeType))?'':'（元）'}` }}</td><td>{{ utils.moneyFormat(item.money) }}</td>
            </tr>
          </tbody>
          <tr>
            <td colspan="2" align="left">
              {{ `合计金额${[12,13,14].includes(Number(feeTypeItem.feeType))?'':'（元）'}:${utils.moneyFormat(feeTypeTotal(feeTypeItem.feeType))}` }}
            </td>
          </tr>
          <tr>
            <td colspan="2" align="left">
              {{ `备注：${feeTypeItem.remarks}` }}
            </td>
          </tr>
        </table>
      </van-cell>
    </div>
    <div class="van-common-detail">
      <van-cell>
        <table class="inner-goods-table payment-table" border="1">
          <tr>
            <td colspan="3" class="header-th">
              <span>货款结算明细</span>
            </td>
          </tr>

          <tbody>
            <tr>
              <td>序号</td><td>项目</td><td>金额（元）</td>
            </tr>
          </tbody>
          <tr v-for="(it,idx) in settlementForm.statementCalculationInfoVoList" :key="idx">
            <td>{{ idx+1 }}</td><td>{{ it.feeProject }}</td><td>{{ utils.moneyFormat(it.feeMoney) }}</td>
          </tr>
        </table>
      </van-cell>
    </div>
    <div class="van-common-detail">
      <van-cell title="本次应收合计（元）">
        {{ utils.moneyFormat(allTotal()) }}
      </van-cell>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Field, Form, Col, Row, Tab, Tabs } from 'vant'
export default {
  name: 'AttachmentList',
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Form.name]: Form,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props: {
    // 所属模块
    settlementDetail: {
      type: Object,
      default () {
        return {}
      }
    },
    type: {
      type: String,
      default: 'collection'
    }
  },
  data () {
    return {
      settlementForm: {
        // 关联合同
        contractNumber: '',
        // 付款方类型
        payerType: '',
        // 付款方企业ID
        payerEnterpriseId: '',
        // 收款方类型
        collectionMethodType: '',
        // 收款方企业ID
        beneficiaryEnterpriseId: '',
        // 付款方开户行
        payerAccountBank: '',
        // 收款方开户行
        beneficiaryAccountBank: '',
        // 付款账号
        paymentAccount: '',
        // 收款账号
        accountsReceivable: '',
        // 费用信息
        statementFeeInfoVoList: [],
        // 货款结算明细
        statementCalculationInfoVoList: [],
        // 结算单金额
        statementMoney: undefined
      },
      loading: false,
      finished: false,
      tableList: {
        list: []
      },
      list: []
    }
  },
  created () {
    this.getStatementDetail()
    this.allTotal()
  },
  methods: {
    getStatementDetail () {
      // this.$route.query.orderId
      this.api.collection.statementDetail({ businessid: this.$route.query.collectionId }).then(result => {
        this.settlementForm = result.data.value
      }).finally(() => {
      })
    },
    feeTypeTotal (feeType) {
      let total = 0
      const list = this._.filter(this.settlementForm.statementFeeInfoVoList, { feeType })
      list.forEach(item => {
        if (item.money) {
          total += Number(item.money)
        }
      })
      const typeData = this._.find(this.settlementForm.statementCalculationInfoVoList, { feeType }) || {}
      typeData.feeMoney = total
      return total
    },
    // 柜号格式化
    cabinetNoFormat (list = [], type) {
      const codeList = []
      if (list) {
        list.forEach(item => {
          codeList.push(item[type])
        })
      }
      return codeList.join(',')
    },
    allTotal () {
      let total = 0
      this.settlementForm.statementCalculationInfoVoList.forEach(item => {
        if (item.feeMoney) {
          total += Number(item.feeMoney)
        }
      })
      this.settlementForm.statementMoney = total
      return total
    }
  }
}
</script>

<style lang="less" scoped>
  .goods-table{
    margin-bottom: 0;
  }
</style>
